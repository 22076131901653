.section__container {
  width: 100%;
  margin: 0 auto;
}

.filter__form__container__distribution {
  display: flex;
  justify-content: flex-end;
  width: auto;
  margin-left: auto;
  margin-bottom: 4em;

  @media (max-width: 1050px) {
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding: 2em 2em;
  }

  @media (max-width: 750px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 50%;
  }
}

.filter__input__options__container {
  display: flex;
  justify-content: space-between;

  @media (max-width: 500px) {
    flex-direction: column;
  }
}

.filter__input__distribution {
  display: block;
  padding: 0.65rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 3px;
  margin-right: 1em;

  @media (max-width: 1050px) {
    margin: 1em 0 1em 0;
  }
}

.filter__input_value {
  color: #495057;
  cursor: pointer;
}

.tables__container {
  position: relative;
}

.header__title {
  font-size: 1.2rem;
  color: gray;
  margin-left: 1em;
}

.financial__spinner {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fcfcfc;
  opacity: 0.7;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spin {
  height: 10vh;
}

.react-datepicker__month-container .react-datepicker-year-header {
  display: none;
}

.month
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__navigation {
  display: none;
}

.month
  .react-datepicker__portal
  .react-datepicker
  .react-datepicker__navigation {
  display: none;
}
