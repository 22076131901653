.info__item__container {
  display: flex;
  justify-content: justified;
  align-items: flex-start;

  @media (max-width: 1000px) {
    flex-wrap: wrap;
  }
}

.info__item__label {
  text-align: left;
  width: max-content;
  width: 30%;
  font-size: 1.2rem;

  @media (max-width: 900px) {
    width: auto;
    margin-right: 0.5em;
    font-size: 1rem;
  }
}

.info__item__value {
  text-align: left;
  width: 55%;
  font-size: 1.2rem;

  @media (max-width: 900px) {
    width: auto;
    margin-right: 0.2em;
    font-size: 1rem;
  }
}
