.sidebar-container {
  //width: 250px;
  min-height: calc(100vh - 50px);
  //background-color: rgb(224, 224, 230);
  //box-shadow: 1px 1px 4px 0 rgb(0 0 0 / 10%);
  position: fixed;
  z-index: 99;
  top: 65px;
  bottom: 0;

  width: 230px;
  background-color: #223e7f;
  padding-left: 10px;
  padding-right: 10px;
  overflow: scroll;
  transition: .3s ease-in-out;

  @media (max-width: 768px) {
    transform: translateX(-100%);
    //top: 85px;
    width: 100vw;
    padding-bottom: 10vh;
  }
}

.show-sidebar {
  transform: translateX(0);
}

.sidebar-section-container {
  display: flex;
  flex-direction: column;
}

.sidebar-message {
  font-size: smaller;
}

.sidebar-section-title {
  display: block;
  text-transform: uppercase;
  font-size: 10px;
  font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
  color: #fff;
  letter-spacing: 1px;
  white-space: nowrap;
  margin-top: 20px;
  padding: 0 10px;
}

.sidebar__link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 25px;
  height: 40px;
  letter-spacing: 0.2px;
  color: #adb5bd;
  font-size: 14px;
  position: relative;
  border-radius: 2px;
  transition: all 0.2s ease-in-out;
  //text-decoration: none;
  //font-size: 1.25rem;
  //margin-top: 0;
  //font-weight: 500;
  //line-height: 1.2;
  //color: #6c757d;
  //margin-bottom: 0.5rem;

  &:hover {
    color: #fff;
  }
}

.sidebar__link-active {
  background-image: none;
  background-repeat: repeat-x;
  color: #fff;
  background-color: #1f3873;
  font-weight: 900;
}

.content-container {
  margin-left: 230px;
  min-height: calc(100vh - 65px);
  margin-top: 65px;
  background-color: #E9ECEF;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  @media (max-width: 768px) {
    margin-left: 0;
  }
}

.logout-container {
  margin-bottom: 30px;
}

.logout-btn {
  margin-top: 20px !important;
  background-color: #fff !important;
  color: #223e7f !important;
  border: 1px solid #223e7f !important;
  transition: background-color 0.3s ease;
}

.logout-btn:hover {
  background-color: rgba(255, 255, 255, 0.8) !important;
  color: #223e7f;
}
