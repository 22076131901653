.filter__form__container {
  display: flex;
  justify-content: flex-end;
  width: auto;
  margin-left: auto;

  @media (max-width: 768px) {
    justify-content: space-around;
    width: 80%;
    margin: 0 auto;
    padding: 2em 2em;
  }

  @media (max-width: 650px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 50%;
  }
}

.agent__title {
  font-weight: 900;
  font-size: 1.2rem;
  margin: 0;

  @media (max-width: 650px) {
    margin: 1em 0;
  }
}

.table__header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 650px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.table__total {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 2em;

  @media (max-width: 650px) {
    margin-right: 0;
  }
}

.horizontal__line {
  width: 50%;
  margin: 4em auto;
  background-color: gray;
  height: 2px;
}

.text-right {
  text-align: right;
}
