.section__container {
  width: 100%;
  margin: 0 auto;
}

.filter__form__container {
  display: flex;
  justify-content: flex-end;
  width: auto;
  margin-left: auto;

  @media (max-width: 768px) {
    justify-content: space-around;
    width: 80%;
    margin: 0 auto;
    padding: 2em 2em;
  }

  @media (max-width: 650px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 50%;
  }
}

.filter__input {
  display: block;
  padding: 0.65rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 3px;
  margin-right: 1em;

  @media (max-width: 650px) {
    margin: 1em 0 1em 0;
  }
}

.tables__container {
  position: relative;
}

.header__title {
  font-size: 1.2rem;
  color: gray;
  margin-left: 1em;
}

.financial__spinner {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fcfcfc;
  opacity: 0.7;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spin {
  height: 10vh;
}

.download-icon {
  width: 2rem;
  height: 2rem;
  margin-left: 1rem;
  cursor: pointer;
}

.download__container {
  display: flex;
  align-items: center;
  margin: 1em 0;

  @media (max-width: 650px) {
    flex-direction: column;
    justify-content: center;
  }
}
