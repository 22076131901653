@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Roboto", sans-serif;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.bg-primary {
    background-color: #223e7f !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
    text-decoration: none;
}

.responsive__table__container {
    overflow-x: auto;
    width: 100%;
    margin-top: 1em;
}

.not__found__title {
    font-size: 250px;
}

.user__page-container {
    margin-bottom: 5rem;
}

.create-enrollment-button {
    position: absolute;
    top: 20px;
    right: 20px;
}

.enrollment-table-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.enrollment-info-container {
    display: flex;
    justify-content: left;
    flex-direction: column;
    padding-top: 3rem;
}

.create-enrollment-modal {
    padding-left: 0 !important;
}

.create-enrollment-body {
    background-color: #223e7f;
    height: 100vh;
}

.chevron-margin {
    margin: 0 15px;
}

.chevron-success {
    color: #28a745;
}

.center-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
}

.step-label {
    font-size: 40px;
    color: #fff;
    margin-bottom: 50px;
}

.select-plan-buttons {
    display: flex;
    justify-content: center;
    gap: 30px;
}

.plan-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 200px;
    cursor: pointer;
    transition: outline 0.3s ease-in-out;
    padding: 15px;
    margin-top: 15px;
}

.plan-button-main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    width: 50%;
    cursor: pointer;
    transition: outline 0.3s ease-in-out;
    margin-top: 15px;
}

.card-label-main {
    font-size: 16px !important;
    margin-bottom: 0 !important;
}

.plan-button:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    outline: 6px solid #28a745;
}

.plan-button-selected {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    outline: 6px solid #28a745;
}

.plan-brand {
    width: 150px;
}

.card-title-centered {
    text-align: center;
    margin-bottom: 25px;
    font-size: 20px;
    font-weight: bold;
}

.card-body-centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.form-section {
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
    background-color: #fff;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15);
    padding: 1.25rem;
    max-width: 900px;
}

.enroll-form-section {
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
    background-color: #fff;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15);
    padding: 1.25rem;
    max-width: 1100px;
    margin: auto;
}

.icon-text {
    display: flex;
    align-items: center;
    font-size: 16px;
}

.icon-text > svg {
    margin-right: 10px;
    width: 150px;
    height: 150px;
    color: #28a745;
}

.signature {
    font-family: 'DarceyOliver', sans-serif;
    font-size: 100px;
}

.signatureCanvas {
    border: 1px solid #ddd; /* Change the color as needed */
}

.quick-enroll-modal .modal-dialog {
    max-width: 900px !important;
}

.quote-modal .modal-dialog {
    max-width: 1200px !important;
}

.send-enroll-modal .modal-dialog {
    max-width: 700px !important;
}

.new-bencat {
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15);
    padding: 15px;
    margin-bottom: 15px
}

.modal-80h {
    height: 80vh;
}

.scrollable-content {
    max-height: 60vh;
    overflow-y: auto;
}

.toast {
    opacity: 1 !important;
}

#toast-container > div {
    opacity: 1 !important;
}

.enroll-container {
    margin-top: 30px;
}

.create-enrollment-button {
    margin-bottom: 20px;
}

.responsive__table__container {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.btn-primary {
    color: #fff;
    background-color: #223e7f;
    border-color: #223e7f;
}

.btn-primary:hover {
    background-color: #1a2f63;
    border-color: #1a2f63;
}

.btn-outline-primary {
    color: #223e7f;
    background-color: #fff;
    border-color: #223e7f;
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: #223e7f;
    border-color: #223e7f;
}

.enrollment-table-container {
    width: 100%;
    max-width: 100%;
    overflow-y: auto;
    max-height: calc(100vh - 130px);
}

.controls-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;
    width: 250px;
}

.filter-container,
.sort-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.filter-container label,
.sort-container label {
    min-width: 50px;
    flex-shrink: 0;
}

.form-select {
    flex-grow: 1;
}

.table-scroll-container {
    padding: 1rem;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.pending-enrollment-table {
    width: 100%;
    min-width: 1000px; /* Adjust this value based on your table's content */
}

.pending-enrollment-table th,
.pending-enrollment-table td {
    white-space: nowrap;
    padding: 0.5rem 1rem;
}

/* Styling the scrollbar */
.table-scroll-container::-webkit-scrollbar {
    padding-top: 1rem;
    height: 8px;
}

.table-scroll-container::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.table-scroll-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.table-scroll-container::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.table-and-controls-wrapper {
    width: 100%;
    overflow-x: auto;
}

@media only screen and (max-width: 575.98px) {
    .header-title {
        display: none;
    }

    .create-enrollment-button {
        text-align: center;
    }

    .responsive__table__container {
        width: 100%;
        margin-bottom: 20px;
    }

    .enrollment-table-container {
        max-height: 50vh;
    }

    .table {
        min-width: auto;
    }

    .form-container {
        width: 570px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767.98px) {
    .header-title {
        display: none;
    }

    .create-enrollment-button {
        text-align: center;
    }

    .responsive__table__container {
        width: 100%;
        margin-bottom: 20px;
    }

    .enrollment-table-container {
        max-height: 60vh;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .header-title {
        display: none;
    }

    .create-enrollment-button {
        text-align: left;
    }

    .responsive__table__container {
        width: 100%;
        margin-bottom: 20px;
    }

    .enrollment-table-container {
        max-height: 70vh;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
    .create-enrollment-button {
        text-align: left;
    }

    .responsive__table__container {
        width: 100%;
    }

    .enrollment-table-container {
        max-height: 75vh;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399.98px) {
    .create-enrollment-button {
        text-align: left;
    }

    .responsive__table__container {
        width: 100%;
    }

    .enrollment-table-container {
        max-height: 80vh;
    }
}

@media only screen and (min-width: 1400px) {
    .create-enrollment-button {
        text-align: left;
    }

    .responsive__table__container {
        width: 100%;
    }

    .enrollment-table-container {
        max-height: 85vh;
    }
}

.pagination {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.pagination-button {
    padding: 5px 10px;
    margin: 0 5px;
    border: 1px solid #007bff;
    background-color: #fff;
    color: #007bff;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.2s;
}

.pagination-button:disabled {
    background-color: #e9ecef;
    color: #6c757d;
    cursor: not-allowed;
}

.pagination-button:hover:not(:disabled) {
    background-color: #007bff;
    color: white;
}

.pagination-select {
    margin-left: 10px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
