.advisor__info__container {
  margin: 1em;
  display: grid;
  grid-template-columns: 1.5fr 1.5fr 1fr;
  grid-template-rows: repeat(3, 1fr);
  grid-auto-flow: column;
  grid-column-gap: 2em;
  grid-row-gap: 0.2em;

  @media (max-width: 1000px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }

  @media (max-width: 750px) {
    display: flex;
    flex-direction: column;
  }
}
