.header__container {
  height: 65px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 99;
  width: 100%;
  box-shadow: 1px 1px 4px 0 rgb(0 0 0 / 10%);

  //@media (max-width: 500px) {
  //  height: 85px;
  //}
}

.logo__container,
.button__container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
  }
}

.button__container {
  flex: 70%;
}

.header__logo {
  width: 250px;
  padding: 10px 20px;
  object-fit: cover;
}

.menu__logo {
  width: 1.5rem;
  margin-left: 1rem;
  cursor: pointer;

  @media (min-width: 800px) {
    display: none;
  }
}

.header__message {
  margin: 0 1em;

  @media (max-width: 500px) {
    margin: 0em 0em 0.5em 0em;
  }
}

.header__link {
  text-decoration: none;
  font-size: 1.25rem;
  margin-top: 0;
  font-weight: 500;
  line-height: 1.2;
  color: #6c757d;

  &:hover {
    color: #0d6efd;
  }
}

.header-title {
  color: #223e7f;
  font-weight: 900;
  letter-spacing: 0.5px;
  margin: 0;
  white-space: nowrap;

  //@media (max-width: 500px) {
  //  margin-top: -25px;
  //}
}

