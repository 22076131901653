.dashboard__container {
  padding: 1rem 100px 1rem 50px;
  flex: 1;
  overflow-y: auto;
  padding-bottom: 20px;
}

.dashboard__title {
  width: 100%;
  font-size: 2rem;
  font-weight: 700;
  color: black;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
}

.dashboard__title span {
  font-size: 1.2rem;
  color: gray;
}
