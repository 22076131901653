.page__container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #223e7f;
}

.form__container {
  width: 100%;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  background-color: #ffffff;
  box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 21%);
  border-radius: 3px;
}

.logo {
  width: 250px;
  margin-bottom: 1em;
}

.signin__form {
  width: 100%;
}

.signin__input {
  display: block;
  width: 100%;
  padding: 0.65rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 3px;
  margin: 1em 0;
}

.signin__button {
  display: block;
  width: 100%;
  padding: 0.65rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 3px;
  background-clip: padding-box;
  margin-top: 1em;
}

.link__container {
  font-size: 12px;
  color: #495057;
  margin-top: 3em;
}

.link {
  font-size: 12px;
  text-decoration: none;
}

.message {
  margin-top: 1em;
  font-weight: 5s00;
  color: rgb(66, 65, 65);
}

.support-contact-link {
  color: darkgray;
  text-decoration: none;
  text-align: center;
  font-size: 12px;
  margin-top: 10px;
}