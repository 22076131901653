.owner__info__container {
  margin: 0.5em;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: repeat(6, 1fr);
  grid-auto-flow: column;
  grid-column-gap: 0em;
  grid-row-gap: 0.2em;

  @media (max-width: 1250px) {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    grid-template-rows: repeat(6, 1fr);
  }

  @media (max-width: 640px) {
    display: flex;
    flex-direction: column;
    margin-bottom: 3em;
  }
}

.distroBtn {
  position: absolute;
  top: 0.5em;
  right: 0.5em;

  @media (max-width: 640px) {
    top: auto;
    bottom: 0.5em;
    right: auto;
  }
}
