@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
.header__container{height:65px;background-color:#fff;display:flex;justify-content:space-between;align-items:center;position:fixed;top:0;z-index:99;width:100%;box-shadow:1px 1px 4px 0 rgba(0,0,0,.1)}.logo__container,.button__container{display:flex;justify-content:space-between;align-items:center}@media(max-width: 800px){.logo__container,.button__container{flex-direction:column;justify-content:center}}.button__container{flex:70% 1}.header__logo{width:250px;padding:10px 20px;object-fit:cover}.menu__logo{width:1.5rem;margin-left:1rem;cursor:pointer}@media(min-width: 800px){.menu__logo{display:none}}.header__message{margin:0 1em}@media(max-width: 500px){.header__message{margin:0em 0em .5em 0em}}.header__link{text-decoration:none;font-size:1.25rem;margin-top:0;font-weight:500;line-height:1.2;color:#6c757d}.header__link:hover{color:#0d6efd}.header-title{color:#223e7f;font-weight:900;letter-spacing:.5px;margin:0;white-space:nowrap}
.sidebar-container{min-height:calc(100vh - 50px);position:fixed;z-index:99;top:65px;bottom:0;width:230px;background-color:#223e7f;padding-left:10px;padding-right:10px;overflow:scroll;transition:.3s ease-in-out}@media(max-width: 768px){.sidebar-container{transform:translateX(-100%);width:100vw;padding-bottom:10vh}}.show-sidebar{transform:translateX(0)}.sidebar-section-container{display:flex;flex-direction:column}.sidebar-message{font-size:smaller}.sidebar-section-title{display:block;text-transform:uppercase;font-size:10px;font-family:"Roboto","Helvetica Neue",Arial,sans-serif;color:#fff;letter-spacing:1px;white-space:nowrap;margin-top:20px;padding:0 10px}.sidebar__link{display:flex;align-items:center;justify-content:flex-start;padding:0 25px;height:40px;letter-spacing:.2px;color:#adb5bd;font-size:14px;position:relative;border-radius:2px;transition:all .2s ease-in-out}.sidebar__link:hover{color:#fff}.sidebar__link-active{background-image:none;background-repeat:repeat-x;color:#fff;background-color:#1f3873;font-weight:900}.content-container{margin-left:230px;min-height:calc(100vh - 65px);margin-top:65px;background-color:#e9ecef;padding-bottom:20px;display:flex;flex-direction:column;overflow-y:auto}@media(max-width: 768px){.content-container{margin-left:0}}.logout-container{margin-bottom:30px}.logout-btn{margin-top:20px !important;background-color:#fff !important;color:#223e7f !important;border:1px solid #223e7f !important;transition:background-color .3s ease}.logout-btn:hover{background-color:rgba(255,255,255,.8) !important;color:#223e7f}
.page__container{width:100%;min-height:100vh;display:flex;justify-content:center;align-items:center;background-color:#223e7f}.form__container{width:100%;max-width:350px;display:flex;flex-direction:column;justify-content:center;align-items:center;padding:40px;background-color:#fff;box-shadow:0px 1px 3px 0px rgba(0,0,0,.21);border-radius:3px}.logo{width:250px;margin-bottom:1em}.signin__form{width:100%}.signin__input{display:block;width:100%;padding:.65rem .75rem;font-size:.875rem;line-height:1.5;color:#495057;background-color:#fff;background-clip:padding-box;border:1px solid #ced4da;border-radius:3px;margin:1em 0}.signin__button{display:block;width:100%;padding:.65rem .75rem;font-size:.875rem;line-height:1.5;border-radius:3px;background-clip:padding-box;margin-top:1em}.link__container{font-size:12px;color:#495057;margin-top:3em}.link{font-size:12px;text-decoration:none}.message{margin-top:1em;font-weight:5s00;color:#424141}.support-contact-link{color:#a9a9a9;text-decoration:none;text-align:center;font-size:12px;margin-top:10px}
@media(max-width: 650px){.page-link{padding:.25rem .5rem;font-size:.875rem}}
.dashboard__container{padding:1rem 100px 1rem 50px;flex:1 1;overflow-y:auto;padding-bottom:20px}.dashboard__title{width:100%;font-size:2rem;font-weight:700;color:#000}@media(max-width: 768px){.dashboard__title{font-size:1.5rem}}.dashboard__title span{font-size:1.2rem;color:gray}
.distro__container{display:flex;flex-direction:column;justify-content:center;align-items:center;width:60%;background-color:#fff;padding:15px 15px 15px 15px;box-shadow:0 0 11px rgba(33,33,33,.2);border-radius:8px;margin:4em auto}@media(max-width: 768px){.distro__container{width:90%}}.distro__title{font-size:2.5rem}@media(max-width: 500px){.distro__title{font-size:1.5rem}}.distro__name{font-size:1.5rem}.distro__item{width:90%;display:flex;flex-direction:column;justify-content:center;align-items:center;background-color:#fff;padding:15px 15px 15px 15px;box-shadow:0 0 11px rgba(33,33,33,.2);border-radius:8px;margin:2em 0;border:2px solid gray}.distro__button{margin-left:auto}
.section__container{width:100%;margin:0 auto}.filter__form__container{display:flex;justify-content:flex-end;width:auto;margin-left:auto}@media(max-width: 768px){.filter__form__container{justify-content:space-around;width:80%;margin:0 auto;padding:2em 2em}}@media(max-width: 650px){.filter__form__container{flex-direction:column;justify-content:center;align-items:center;padding:0;width:50%}}.filter__input{display:block;padding:.65rem .75rem;font-size:.875rem;line-height:1.5;color:#495057;background-color:#fff;background-clip:padding-box;border:1px solid #ced4da;border-radius:3px;margin-right:1em}@media(max-width: 650px){.filter__input{margin:1em 0 1em 0}}.tables__container{position:relative}.header__title{font-size:1.2rem;color:gray;margin-left:1em}.financial__spinner{position:absolute;top:0;width:100%;height:100%;background-color:#fcfcfc;opacity:.7;display:flex;justify-content:center;align-items:center}.spin{height:10vh}.download-icon{width:2rem;height:2rem;margin-left:1rem;cursor:pointer}.download__container{display:flex;align-items:center;margin:1em 0}@media(max-width: 650px){.download__container{flex-direction:column;justify-content:center}}
.filter__form__container{display:flex;justify-content:flex-end;width:auto;margin-left:auto}@media(max-width: 768px){.filter__form__container{justify-content:space-around;width:80%;margin:0 auto;padding:2em 2em}}@media(max-width: 650px){.filter__form__container{flex-direction:column;justify-content:center;align-items:center;padding:0;width:50%}}.agent__title{font-weight:900;font-size:1.2rem;margin:0}@media(max-width: 650px){.agent__title{margin:1em 0}}.table__header{display:flex;justify-content:space-between;align-items:center}@media(max-width: 650px){.table__header{flex-direction:column;justify-content:flex-start;align-items:flex-start}}.table__total{display:flex;justify-content:flex-end;align-items:center;margin-right:2em}@media(max-width: 650px){.table__total{margin-right:0}}.horizontal__line{width:50%;margin:4em auto;background-color:gray;height:2px}.text-right{text-align:right}
.section__container{width:100%;margin:0 auto}.filter__form__container__distribution{display:flex;justify-content:flex-end;width:auto;margin-left:auto;margin-bottom:4em}@media(max-width: 1050px){.filter__form__container__distribution{justify-content:space-around;flex-direction:column;align-items:center;margin:0 auto;padding:2em 2em}}@media(max-width: 750px){.filter__form__container__distribution{flex-direction:column;justify-content:center;align-items:center;padding:0;width:50%}}.filter__input__options__container{display:flex;justify-content:space-between}@media(max-width: 500px){.filter__input__options__container{flex-direction:column}}.filter__input__distribution{display:block;padding:.65rem .75rem;font-size:.875rem;line-height:1.5;color:#495057;background-color:#fff;background-clip:padding-box;border:1px solid #ced4da;border-radius:3px;margin-right:1em}@media(max-width: 1050px){.filter__input__distribution{margin:1em 0 1em 0}}.filter__input_value{color:#495057;cursor:pointer}.tables__container{position:relative}.header__title{font-size:1.2rem;color:gray;margin-left:1em}.financial__spinner{position:absolute;top:0;width:100%;height:100%;background-color:#fcfcfc;opacity:.7;display:flex;justify-content:center;align-items:center}.spin{height:10vh}.react-datepicker__month-container .react-datepicker-year-header{display:none}.month .react-datepicker-popper .react-datepicker .react-datepicker__navigation{display:none}.month .react-datepicker__portal .react-datepicker .react-datepicker__navigation{display:none}
.info__item__container{display:flex;justify-content:justified;align-items:flex-start}@media(max-width: 1000px){.info__item__container{flex-wrap:wrap}}.info__item__label{text-align:left;width:-webkit-max-content;width:max-content;width:30%;font-size:1.2rem}@media(max-width: 900px){.info__item__label{width:auto;margin-right:.5em;font-size:1rem}}.info__item__value{text-align:left;width:55%;font-size:1.2rem}@media(max-width: 900px){.info__item__value{width:auto;margin-right:.2em;font-size:1rem}}
.advisor__info__container{margin:1em;display:grid;grid-template-columns:1.5fr 1.5fr 1fr;grid-template-rows:repeat(3, 1fr);grid-auto-flow:column;grid-column-gap:2em;grid-row-gap:.2em}@media(max-width: 1000px){.advisor__info__container{display:grid;grid-template-columns:repeat(2, 1fr);grid-template-rows:repeat(3, 1fr)}}@media(max-width: 750px){.advisor__info__container{display:flex;flex-direction:column}}
.owner__info__container{margin:.5em;display:grid;grid-template-columns:auto auto;grid-template-rows:repeat(6, 1fr);grid-auto-flow:column;grid-column-gap:0em;grid-row-gap:.2em}@media(max-width: 1250px){.owner__info__container{display:grid;grid-template-columns:1.5fr 1fr;grid-template-rows:repeat(6, 1fr)}}@media(max-width: 640px){.owner__info__container{display:flex;flex-direction:column;margin-bottom:3em}}.distroBtn{position:absolute;top:.5em;right:.5em}@media(max-width: 640px){.distroBtn{top:auto;bottom:.5em;right:auto}}
@font-face {
    font-family: 'DarceyOliver';
    src: url(/static/media/DarceyOliver.54090146.otf) format('opentype');
}
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Roboto", sans-serif;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.bg-primary {
    background-color: #223e7f !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
    text-decoration: none;
}

.responsive__table__container {
    overflow-x: auto;
    width: 100%;
    margin-top: 1em;
}

.not__found__title {
    font-size: 250px;
}

.user__page-container {
    margin-bottom: 5rem;
}

.create-enrollment-button {
    position: absolute;
    top: 20px;
    right: 20px;
}

.enrollment-table-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.enrollment-info-container {
    display: flex;
    justify-content: left;
    flex-direction: column;
    padding-top: 3rem;
}

.create-enrollment-modal {
    padding-left: 0 !important;
}

.create-enrollment-body {
    background-color: #223e7f;
    height: 100vh;
}

.chevron-margin {
    margin: 0 15px;
}

.chevron-success {
    color: #28a745;
}

.center-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
}

.step-label {
    font-size: 40px;
    color: #fff;
    margin-bottom: 50px;
}

.select-plan-buttons {
    display: flex;
    justify-content: center;
    grid-gap: 30px;
    gap: 30px;
}

.plan-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 200px;
    cursor: pointer;
    transition: outline 0.3s ease-in-out;
    padding: 15px;
    margin-top: 15px;
}

.plan-button-main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    width: 50%;
    cursor: pointer;
    transition: outline 0.3s ease-in-out;
    margin-top: 15px;
}

.card-label-main {
    font-size: 16px !important;
    margin-bottom: 0 !important;
}

.plan-button:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    outline: 6px solid #28a745;
}

.plan-button-selected {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    outline: 6px solid #28a745;
}

.plan-brand {
    width: 150px;
}

.card-title-centered {
    text-align: center;
    margin-bottom: 25px;
    font-size: 20px;
    font-weight: bold;
}

.card-body-centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.form-section {
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
    background-color: #fff;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15);
    padding: 1.25rem;
    max-width: 900px;
}

.enroll-form-section {
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
    background-color: #fff;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15);
    padding: 1.25rem;
    max-width: 1100px;
    margin: auto;
}

.icon-text {
    display: flex;
    align-items: center;
    font-size: 16px;
}

.icon-text > svg {
    margin-right: 10px;
    width: 150px;
    height: 150px;
    color: #28a745;
}

.signature {
    font-family: 'DarceyOliver', sans-serif;
    font-size: 100px;
}

.signatureCanvas {
    border: 1px solid #ddd; /* Change the color as needed */
}

.quick-enroll-modal .modal-dialog {
    max-width: 900px !important;
}

.quote-modal .modal-dialog {
    max-width: 1200px !important;
}

.send-enroll-modal .modal-dialog {
    max-width: 700px !important;
}

.new-bencat {
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15);
    padding: 15px;
    margin-bottom: 15px
}

.modal-80h {
    height: 80vh;
}

.scrollable-content {
    max-height: 60vh;
    overflow-y: auto;
}

.toast {
    opacity: 1 !important;
}

#toast-container > div {
    opacity: 1 !important;
}

.enroll-container {
    margin-top: 30px;
}

.create-enrollment-button {
    margin-bottom: 20px;
}

.responsive__table__container {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.btn-primary {
    color: #fff;
    background-color: #223e7f;
    border-color: #223e7f;
}

.btn-primary:hover {
    background-color: #1a2f63;
    border-color: #1a2f63;
}

.btn-outline-primary {
    color: #223e7f;
    background-color: #fff;
    border-color: #223e7f;
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: #223e7f;
    border-color: #223e7f;
}

.enrollment-table-container {
    width: 100%;
    max-width: 100%;
    overflow-y: auto;
    max-height: calc(100vh - 130px);
}

.controls-container {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    margin-bottom: 1rem;
    width: 250px;
}

.filter-container,
.sort-container {
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.filter-container label,
.sort-container label {
    min-width: 50px;
    flex-shrink: 0;
}

.form-select {
    flex-grow: 1;
}

.table-scroll-container {
    padding: 1rem;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.pending-enrollment-table {
    width: 100%;
    min-width: 1000px; /* Adjust this value based on your table's content */
}

.pending-enrollment-table th,
.pending-enrollment-table td {
    white-space: nowrap;
    padding: 0.5rem 1rem;
}

/* Styling the scrollbar */
.table-scroll-container::-webkit-scrollbar {
    padding-top: 1rem;
    height: 8px;
}

.table-scroll-container::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.table-scroll-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.table-scroll-container::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.table-and-controls-wrapper {
    width: 100%;
    overflow-x: auto;
}

@media only screen and (max-width: 575.98px) {
    .header-title {
        display: none;
    }

    .create-enrollment-button {
        text-align: center;
    }

    .responsive__table__container {
        width: 100%;
        margin-bottom: 20px;
    }

    .enrollment-table-container {
        max-height: 50vh;
    }

    .table {
        min-width: auto;
    }

    .form-container {
        width: 570px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767.98px) {
    .header-title {
        display: none;
    }

    .create-enrollment-button {
        text-align: center;
    }

    .responsive__table__container {
        width: 100%;
        margin-bottom: 20px;
    }

    .enrollment-table-container {
        max-height: 60vh;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .header-title {
        display: none;
    }

    .create-enrollment-button {
        text-align: left;
    }

    .responsive__table__container {
        width: 100%;
        margin-bottom: 20px;
    }

    .enrollment-table-container {
        max-height: 70vh;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
    .create-enrollment-button {
        text-align: left;
    }

    .responsive__table__container {
        width: 100%;
    }

    .enrollment-table-container {
        max-height: 75vh;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399.98px) {
    .create-enrollment-button {
        text-align: left;
    }

    .responsive__table__container {
        width: 100%;
    }

    .enrollment-table-container {
        max-height: 80vh;
    }
}

@media only screen and (min-width: 1400px) {
    .create-enrollment-button {
        text-align: left;
    }

    .responsive__table__container {
        width: 100%;
    }

    .enrollment-table-container {
        max-height: 85vh;
    }
}

.pagination {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.pagination-button {
    padding: 5px 10px;
    margin: 0 5px;
    border: 1px solid #007bff;
    background-color: #fff;
    color: #007bff;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.2s;
}

.pagination-button:disabled {
    background-color: #e9ecef;
    color: #6c757d;
    cursor: not-allowed;
}

.pagination-button:hover:not(:disabled) {
    background-color: #007bff;
    color: white;
}

.pagination-select {
    margin-left: 10px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

