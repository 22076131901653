.distro__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  background-color: #fff;
  padding: 15px 15px 15px 15px;
  box-shadow: 0 0 11px rgb(33 33 33 / 20%);
  border-radius: 8px;
  margin: 4em auto;

  @media (max-width: 768px) {
    width: 90%;
  }
}

.distro__title {
  font-size: 2.5rem;

  @media (max-width: 500px) {
    font-size: 1.5rem;
  }
}

.distro__name {
  font-size: 1.5rem;
}

.distro__item {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 15px 15px 15px 15px;
  box-shadow: 0 0 11px rgb(33 33 33 / 20%);
  border-radius: 8px;
  margin: 2em 0;
  border: 2px solid gray;
}

.distro__button {
  margin-left: auto;
}
